import { getClasses } from '@pasqal/core/helpers/styles';
import Button from '@pasqal/core/ui/components/button/Button';
import { signUp } from '~/utils/auth';
import type { ComponentWithChildren } from '@pasqal/core/ui/components/types';

interface Props extends ComponentWithChildren {
  className?: string;
  testId?: string;
}

export const SignupButton = ({ className, children, testId }: Props) => {
  const css = getClasses(['SignupButton', className]);

  const handleSignUp = async () => {
    if (signUp) {
      await signUp();
    }
  };

  return (
    <Button className={css} onClick={handleSignUp} testId={testId}>
      {children}
    </Button>
  );
};
